import { default as indexvGgAHIagnXMeta } from "/app/pages/[companyname]/index.vue?macro=true";
import { default as _91encodedemail_93BUmugMjoZ5Meta } from "/app/pages/[companyname]/rsvp/[id]/[encodedemail].vue?macro=true";
import { default as backup_45_91encodedemail_93_45with_45adult_45childjQNl0xSx3WMeta } from "/app/pages/[companyname]/rsvp/[id]/backup-[encodedemail]-with-adult-child.vue?macro=true";
import { default as index0kcSDBwDTQMeta } from "/app/pages/announcements/index.vue?macro=true";
import { default as indexhg2nWJrbRRMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as dashboardewT5qYF5VoMeta } from "/app/pages/database/dashboard.vue?macro=true";
import { default as _91id_93CCusQpLdUTMeta } from "/app/pages/database/records/[id].vue?macro=true";
import { default as listxOT7CjwOPlMeta } from "/app/pages/database/records/list.vue?macro=true";
import { default as oldIdxG5v9YsWUnMeta } from "/app/pages/database/records/oldId.vue?macro=true";
import { default as event_45attendance_45scannerTvGNJEImW2Meta } from "/app/pages/event-attendance-scanner.vue?macro=true";
import { default as event_45attendanceNhjDDbXA2GMeta } from "/app/pages/event-attendance.vue?macro=true";
import { default as indexnqGPcl8Ho6Meta } from "/app/pages/events/[id]/index.vue?macro=true";
import { default as ticketsDqxvdTlLRRMeta } from "/app/pages/events/[id]/tickets.vue?macro=true";
import { default as add_45updateZJGTiblPOjMeta } from "/app/pages/events/add-update.vue?macro=true";
import { default as indexWgIHVzns1AMeta } from "/app/pages/events/index.vue?macro=true";
import { default as indexpcgO0qLbvhMeta } from "/app/pages/families/index.vue?macro=true";
import { default as approvalucIxuLBxz6Meta } from "/app/pages/feed/[clubid]/approval.vue?macro=true";
import { default as contest_45adminrXQi67PuqHMeta } from "/app/pages/feed/[clubid]/contest-admin.vue?macro=true";
import { default as photo_45galleryuGPeVwTCTCMeta } from "/app/pages/feed/[clubid]/photo-gallery.vue?macro=true";
import { default as qr48ON1TpSNTMeta } from "/app/pages/feed/[clubid]/qr.vue?macro=true";
import { default as indexYhIv6bWPrQMeta } from "/app/pages/feed/[companycode]/[clubid]/index.vue?macro=true";
import { default as new_45contestFcoUuxO3H9Meta } from "/app/pages/feed/[companycode]/[clubid]/new-contest.vue?macro=true";
import { default as indexqEMZ3pR9NJMeta } from "/app/pages/fees/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexjq7Ho9BMUYMeta } from "/app/pages/invoices/index.vue?macro=true";
import { default as _91redirectpath_93Ly4zKbj6SnMeta } from "/app/pages/junction/short-id-validate/[shortid]/[redirectpath].vue?macro=true";
import { default as ms_45redirect_45herepBR5balpmzMeta } from "/app/pages/ms-redirect-here.vue?macro=true";
import { default as change_45passwordVj4R8BjKaXMeta } from "/app/pages/my-profile/change-password.vue?macro=true";
import { default as indexbIcMYOjlfRMeta } from "/app/pages/my-profile/index.vue?macro=true";
import { default as newpageYRYNpL8DSSMeta } from "/app/pages/newpage.vue?macro=true";
import { default as payment_45successiL8KAe8rlNMeta } from "/app/pages/no-auth/text-messaging/payment-success.vue?macro=true";
import { default as plansgxLq0bCpm3Meta } from "/app/pages/no-auth/text-messaging/plans.vue?macro=true";
import { default as ParentZ6ZqOwRvvZMeta } from "/app/pages/Parent.vue?macro=true";
import { default as indexovVtp4PBUXMeta } from "/app/pages/participants/[id]/index.vue?macro=true";
import { default as allHFDNPBw4rLMeta } from "/app/pages/participants/all.vue?macro=true";
import { default as history_45for_45program2J5bbDiFkpMeta } from "/app/pages/payments/history-for-program.vue?macro=true";
import { default as historyabBw7azUgaMeta } from "/app/pages/payments/history.vue?macro=true";
import { default as transaction3kvvK3OPPhMeta } from "/app/pages/program/[clubId]/transaction.vue?macro=true";
import { default as indexu9QcDESI3cMeta } from "/app/pages/program/index.vue?macro=true";
import { default as index3wV7fz535qMeta } from "/app/pages/programs/index.vue?macro=true";
import { default as indexEAzWAcRdfRMeta } from "/app/pages/rsvp/[id]/index.vue?macro=true";
import { default as index3PcRRShzyvMeta } from "/app/pages/rsvp/index.vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
import { default as indexyNQEpNx7yEMeta } from "/app/pages/students/index.vue?macro=true";
import { default as payment_45successrUBkSkXmASMeta } from "/app/pages/text-messaging/payment-success.vue?macro=true";
import { default as plansWfo8Jh1TCwMeta } from "/app/pages/text-messaging/plans.vue?macro=true";
import { default as usage_45historyxqHZ1EZbn3Meta } from "/app/pages/text-messaging/usage-history.vue?macro=true";
import { default as indexL9SrZLs43zMeta } from "/app/pages/transaction/index.vue?macro=true";
import { default as unauthorizedUnTSWnoGh4Meta } from "/app/pages/unauthorized.vue?macro=true";
import { default as indexnq54o81oozMeta } from "/app/pages/users/index.vue?macro=true";
export default [
  {
    name: indexvGgAHIagnXMeta?.name ?? "companyname",
    path: indexvGgAHIagnXMeta?.path ?? "/:companyname()",
    meta: indexvGgAHIagnXMeta || {},
    alias: indexvGgAHIagnXMeta?.alias || [],
    redirect: indexvGgAHIagnXMeta?.redirect,
    component: () => import("/app/pages/[companyname]/index.vue").then(m => m.default || m)
  },
  {
    name: _91encodedemail_93BUmugMjoZ5Meta?.name ?? "companyname-rsvp-id-encodedemail",
    path: _91encodedemail_93BUmugMjoZ5Meta?.path ?? "/:companyname()/rsvp/:id()/:encodedemail()",
    meta: _91encodedemail_93BUmugMjoZ5Meta || {},
    alias: _91encodedemail_93BUmugMjoZ5Meta?.alias || [],
    redirect: _91encodedemail_93BUmugMjoZ5Meta?.redirect,
    component: () => import("/app/pages/[companyname]/rsvp/[id]/[encodedemail].vue").then(m => m.default || m)
  },
  {
    name: backup_45_91encodedemail_93_45with_45adult_45childjQNl0xSx3WMeta?.name ?? "companyname-rsvp-id-backup-encodedemail-with-adult-child",
    path: backup_45_91encodedemail_93_45with_45adult_45childjQNl0xSx3WMeta?.path ?? "/:companyname()/rsvp/:id()/backup-:encodedemail()-with-adult-child",
    meta: backup_45_91encodedemail_93_45with_45adult_45childjQNl0xSx3WMeta || {},
    alias: backup_45_91encodedemail_93_45with_45adult_45childjQNl0xSx3WMeta?.alias || [],
    redirect: backup_45_91encodedemail_93_45with_45adult_45childjQNl0xSx3WMeta?.redirect,
    component: () => import("/app/pages/[companyname]/rsvp/[id]/backup-[encodedemail]-with-adult-child.vue").then(m => m.default || m)
  },
  {
    name: index0kcSDBwDTQMeta?.name ?? "announcements",
    path: index0kcSDBwDTQMeta?.path ?? "/announcements",
    meta: index0kcSDBwDTQMeta || {},
    alias: index0kcSDBwDTQMeta?.alias || [],
    redirect: index0kcSDBwDTQMeta?.redirect,
    component: () => import("/app/pages/announcements/index.vue").then(m => m.default || m)
  },
  {
    name: indexhg2nWJrbRRMeta?.name ?? "dashboard",
    path: indexhg2nWJrbRRMeta?.path ?? "/dashboard",
    meta: indexhg2nWJrbRRMeta || {},
    alias: indexhg2nWJrbRRMeta?.alias || [],
    redirect: indexhg2nWJrbRRMeta?.redirect,
    component: () => import("/app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardewT5qYF5VoMeta?.name ?? "database-dashboard",
    path: dashboardewT5qYF5VoMeta?.path ?? "/database/dashboard",
    meta: dashboardewT5qYF5VoMeta || {},
    alias: dashboardewT5qYF5VoMeta?.alias || [],
    redirect: dashboardewT5qYF5VoMeta?.redirect,
    component: () => import("/app/pages/database/dashboard.vue").then(m => m.default || m)
  },
  {
    name: _91id_93CCusQpLdUTMeta?.name ?? "database-records-id",
    path: _91id_93CCusQpLdUTMeta?.path ?? "/database/records/:id()",
    meta: _91id_93CCusQpLdUTMeta || {},
    alias: _91id_93CCusQpLdUTMeta?.alias || [],
    redirect: _91id_93CCusQpLdUTMeta?.redirect,
    component: () => import("/app/pages/database/records/[id].vue").then(m => m.default || m)
  },
  {
    name: listxOT7CjwOPlMeta?.name ?? "database-records-list",
    path: listxOT7CjwOPlMeta?.path ?? "/database/records/list",
    meta: listxOT7CjwOPlMeta || {},
    alias: listxOT7CjwOPlMeta?.alias || [],
    redirect: listxOT7CjwOPlMeta?.redirect,
    component: () => import("/app/pages/database/records/list.vue").then(m => m.default || m)
  },
  {
    name: oldIdxG5v9YsWUnMeta?.name ?? "database-records-oldId",
    path: oldIdxG5v9YsWUnMeta?.path ?? "/database/records/oldId",
    meta: oldIdxG5v9YsWUnMeta || {},
    alias: oldIdxG5v9YsWUnMeta?.alias || [],
    redirect: oldIdxG5v9YsWUnMeta?.redirect,
    component: () => import("/app/pages/database/records/oldId.vue").then(m => m.default || m)
  },
  {
    name: event_45attendance_45scannerTvGNJEImW2Meta?.name ?? "event-attendance-scanner",
    path: event_45attendance_45scannerTvGNJEImW2Meta?.path ?? "/event-attendance-scanner",
    meta: event_45attendance_45scannerTvGNJEImW2Meta || {},
    alias: event_45attendance_45scannerTvGNJEImW2Meta?.alias || [],
    redirect: event_45attendance_45scannerTvGNJEImW2Meta?.redirect,
    component: () => import("/app/pages/event-attendance-scanner.vue").then(m => m.default || m)
  },
  {
    name: event_45attendanceNhjDDbXA2GMeta?.name ?? "event-attendance",
    path: event_45attendanceNhjDDbXA2GMeta?.path ?? "/event-attendance",
    meta: event_45attendanceNhjDDbXA2GMeta || {},
    alias: event_45attendanceNhjDDbXA2GMeta?.alias || [],
    redirect: event_45attendanceNhjDDbXA2GMeta?.redirect,
    component: () => import("/app/pages/event-attendance.vue").then(m => m.default || m)
  },
  {
    name: indexnqGPcl8Ho6Meta?.name ?? "events-id",
    path: indexnqGPcl8Ho6Meta?.path ?? "/events/:id()",
    meta: indexnqGPcl8Ho6Meta || {},
    alias: indexnqGPcl8Ho6Meta?.alias || [],
    redirect: indexnqGPcl8Ho6Meta?.redirect,
    component: () => import("/app/pages/events/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: ticketsDqxvdTlLRRMeta?.name ?? "events-id-tickets",
    path: ticketsDqxvdTlLRRMeta?.path ?? "/events/:id()/tickets",
    meta: ticketsDqxvdTlLRRMeta || {},
    alias: ticketsDqxvdTlLRRMeta?.alias || [],
    redirect: ticketsDqxvdTlLRRMeta?.redirect,
    component: () => import("/app/pages/events/[id]/tickets.vue").then(m => m.default || m)
  },
  {
    name: add_45updateZJGTiblPOjMeta?.name ?? "events-add-update",
    path: add_45updateZJGTiblPOjMeta?.path ?? "/events/add-update",
    meta: add_45updateZJGTiblPOjMeta || {},
    alias: add_45updateZJGTiblPOjMeta?.alias || [],
    redirect: add_45updateZJGTiblPOjMeta?.redirect,
    component: () => import("/app/pages/events/add-update.vue").then(m => m.default || m)
  },
  {
    name: indexWgIHVzns1AMeta?.name ?? "events",
    path: indexWgIHVzns1AMeta?.path ?? "/events",
    meta: indexWgIHVzns1AMeta || {},
    alias: indexWgIHVzns1AMeta?.alias || [],
    redirect: indexWgIHVzns1AMeta?.redirect,
    component: () => import("/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexpcgO0qLbvhMeta?.name ?? "families",
    path: indexpcgO0qLbvhMeta?.path ?? "/families",
    meta: indexpcgO0qLbvhMeta || {},
    alias: indexpcgO0qLbvhMeta?.alias || [],
    redirect: indexpcgO0qLbvhMeta?.redirect,
    component: () => import("/app/pages/families/index.vue").then(m => m.default || m)
  },
  {
    name: approvalucIxuLBxz6Meta?.name ?? "feed-clubid-approval",
    path: approvalucIxuLBxz6Meta?.path ?? "/feed/:clubid()/approval",
    meta: approvalucIxuLBxz6Meta || {},
    alias: approvalucIxuLBxz6Meta?.alias || [],
    redirect: approvalucIxuLBxz6Meta?.redirect,
    component: () => import("/app/pages/feed/[clubid]/approval.vue").then(m => m.default || m)
  },
  {
    name: contest_45adminrXQi67PuqHMeta?.name ?? "feed-clubid-contest-admin",
    path: contest_45adminrXQi67PuqHMeta?.path ?? "/feed/:clubid()/contest-admin",
    meta: contest_45adminrXQi67PuqHMeta || {},
    alias: contest_45adminrXQi67PuqHMeta?.alias || [],
    redirect: contest_45adminrXQi67PuqHMeta?.redirect,
    component: () => import("/app/pages/feed/[clubid]/contest-admin.vue").then(m => m.default || m)
  },
  {
    name: photo_45galleryuGPeVwTCTCMeta?.name ?? "feed-clubid-photo-gallery",
    path: photo_45galleryuGPeVwTCTCMeta?.path ?? "/feed/:clubid()/photo-gallery",
    meta: photo_45galleryuGPeVwTCTCMeta || {},
    alias: photo_45galleryuGPeVwTCTCMeta?.alias || [],
    redirect: photo_45galleryuGPeVwTCTCMeta?.redirect,
    component: () => import("/app/pages/feed/[clubid]/photo-gallery.vue").then(m => m.default || m)
  },
  {
    name: qr48ON1TpSNTMeta?.name ?? "feed-clubid-qr",
    path: qr48ON1TpSNTMeta?.path ?? "/feed/:clubid()/qr",
    meta: qr48ON1TpSNTMeta || {},
    alias: qr48ON1TpSNTMeta?.alias || [],
    redirect: qr48ON1TpSNTMeta?.redirect,
    component: () => import("/app/pages/feed/[clubid]/qr.vue").then(m => m.default || m)
  },
  {
    name: indexYhIv6bWPrQMeta?.name ?? "feed-companycode-clubid",
    path: indexYhIv6bWPrQMeta?.path ?? "/feed/:companycode()/:clubid()",
    meta: indexYhIv6bWPrQMeta || {},
    alias: indexYhIv6bWPrQMeta?.alias || [],
    redirect: indexYhIv6bWPrQMeta?.redirect,
    component: () => import("/app/pages/feed/[companycode]/[clubid]/index.vue").then(m => m.default || m)
  },
  {
    name: new_45contestFcoUuxO3H9Meta?.name ?? "feed-companycode-clubid-new-contest",
    path: new_45contestFcoUuxO3H9Meta?.path ?? "/feed/:companycode()/:clubid()/new-contest",
    meta: new_45contestFcoUuxO3H9Meta || {},
    alias: new_45contestFcoUuxO3H9Meta?.alias || [],
    redirect: new_45contestFcoUuxO3H9Meta?.redirect,
    component: () => import("/app/pages/feed/[companycode]/[clubid]/new-contest.vue").then(m => m.default || m)
  },
  {
    name: indexqEMZ3pR9NJMeta?.name ?? "fees",
    path: indexqEMZ3pR9NJMeta?.path ?? "/fees",
    meta: indexqEMZ3pR9NJMeta || {},
    alias: indexqEMZ3pR9NJMeta?.alias || [],
    redirect: indexqEMZ3pR9NJMeta?.redirect,
    component: () => import("/app/pages/fees/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjq7Ho9BMUYMeta?.name ?? "invoices",
    path: indexjq7Ho9BMUYMeta?.path ?? "/invoices",
    meta: indexjq7Ho9BMUYMeta || {},
    alias: indexjq7Ho9BMUYMeta?.alias || [],
    redirect: indexjq7Ho9BMUYMeta?.redirect,
    component: () => import("/app/pages/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: _91redirectpath_93Ly4zKbj6SnMeta?.name ?? "junction-short-id-validate-shortid-redirectpath",
    path: _91redirectpath_93Ly4zKbj6SnMeta?.path ?? "/junction/short-id-validate/:shortid()/:redirectpath()",
    meta: _91redirectpath_93Ly4zKbj6SnMeta || {},
    alias: _91redirectpath_93Ly4zKbj6SnMeta?.alias || [],
    redirect: _91redirectpath_93Ly4zKbj6SnMeta?.redirect,
    component: () => import("/app/pages/junction/short-id-validate/[shortid]/[redirectpath].vue").then(m => m.default || m)
  },
  {
    name: ms_45redirect_45herepBR5balpmzMeta?.name ?? "ms-redirect-here",
    path: ms_45redirect_45herepBR5balpmzMeta?.path ?? "/ms-redirect-here",
    meta: ms_45redirect_45herepBR5balpmzMeta || {},
    alias: ms_45redirect_45herepBR5balpmzMeta?.alias || [],
    redirect: ms_45redirect_45herepBR5balpmzMeta?.redirect,
    component: () => import("/app/pages/ms-redirect-here.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordVj4R8BjKaXMeta?.name ?? "my-profile-change-password",
    path: change_45passwordVj4R8BjKaXMeta?.path ?? "/my-profile/change-password",
    meta: change_45passwordVj4R8BjKaXMeta || {},
    alias: change_45passwordVj4R8BjKaXMeta?.alias || [],
    redirect: change_45passwordVj4R8BjKaXMeta?.redirect,
    component: () => import("/app/pages/my-profile/change-password.vue").then(m => m.default || m)
  },
  {
    name: indexbIcMYOjlfRMeta?.name ?? "my-profile",
    path: indexbIcMYOjlfRMeta?.path ?? "/my-profile",
    meta: indexbIcMYOjlfRMeta || {},
    alias: indexbIcMYOjlfRMeta?.alias || [],
    redirect: indexbIcMYOjlfRMeta?.redirect,
    component: () => import("/app/pages/my-profile/index.vue").then(m => m.default || m)
  },
  {
    name: newpageYRYNpL8DSSMeta?.name ?? "newpage",
    path: newpageYRYNpL8DSSMeta?.path ?? "/newpage",
    meta: newpageYRYNpL8DSSMeta || {},
    alias: newpageYRYNpL8DSSMeta?.alias || [],
    redirect: newpageYRYNpL8DSSMeta?.redirect,
    component: () => import("/app/pages/newpage.vue").then(m => m.default || m)
  },
  {
    name: payment_45successiL8KAe8rlNMeta?.name ?? "no-auth-text-messaging-payment-success",
    path: payment_45successiL8KAe8rlNMeta?.path ?? "/no-auth/text-messaging/payment-success",
    meta: payment_45successiL8KAe8rlNMeta || {},
    alias: payment_45successiL8KAe8rlNMeta?.alias || [],
    redirect: payment_45successiL8KAe8rlNMeta?.redirect,
    component: () => import("/app/pages/no-auth/text-messaging/payment-success.vue").then(m => m.default || m)
  },
  {
    name: plansgxLq0bCpm3Meta?.name ?? "no-auth-text-messaging-plans",
    path: plansgxLq0bCpm3Meta?.path ?? "/no-auth/text-messaging/plans",
    meta: plansgxLq0bCpm3Meta || {},
    alias: plansgxLq0bCpm3Meta?.alias || [],
    redirect: plansgxLq0bCpm3Meta?.redirect,
    component: () => import("/app/pages/no-auth/text-messaging/plans.vue").then(m => m.default || m)
  },
  {
    name: ParentZ6ZqOwRvvZMeta?.name ?? "Parent",
    path: ParentZ6ZqOwRvvZMeta?.path ?? "/Parent",
    meta: ParentZ6ZqOwRvvZMeta || {},
    alias: ParentZ6ZqOwRvvZMeta?.alias || [],
    redirect: ParentZ6ZqOwRvvZMeta?.redirect,
    component: () => import("/app/pages/Parent.vue").then(m => m.default || m)
  },
  {
    name: indexovVtp4PBUXMeta?.name ?? "participants-id",
    path: indexovVtp4PBUXMeta?.path ?? "/participants/:id()",
    meta: indexovVtp4PBUXMeta || {},
    alias: indexovVtp4PBUXMeta?.alias || [],
    redirect: indexovVtp4PBUXMeta?.redirect,
    component: () => import("/app/pages/participants/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: allHFDNPBw4rLMeta?.name ?? "participants-all",
    path: allHFDNPBw4rLMeta?.path ?? "/participants/all",
    meta: allHFDNPBw4rLMeta || {},
    alias: allHFDNPBw4rLMeta?.alias || [],
    redirect: allHFDNPBw4rLMeta?.redirect,
    component: () => import("/app/pages/participants/all.vue").then(m => m.default || m)
  },
  {
    name: history_45for_45program2J5bbDiFkpMeta?.name ?? "payments-history-for-program",
    path: history_45for_45program2J5bbDiFkpMeta?.path ?? "/payments/history-for-program",
    meta: history_45for_45program2J5bbDiFkpMeta || {},
    alias: history_45for_45program2J5bbDiFkpMeta?.alias || [],
    redirect: history_45for_45program2J5bbDiFkpMeta?.redirect,
    component: () => import("/app/pages/payments/history-for-program.vue").then(m => m.default || m)
  },
  {
    name: historyabBw7azUgaMeta?.name ?? "payments-history",
    path: historyabBw7azUgaMeta?.path ?? "/payments/history",
    meta: historyabBw7azUgaMeta || {},
    alias: historyabBw7azUgaMeta?.alias || [],
    redirect: historyabBw7azUgaMeta?.redirect,
    component: () => import("/app/pages/payments/history.vue").then(m => m.default || m)
  },
  {
    name: transaction3kvvK3OPPhMeta?.name ?? "program-clubId-transaction",
    path: transaction3kvvK3OPPhMeta?.path ?? "/program/:clubId()/transaction",
    meta: transaction3kvvK3OPPhMeta || {},
    alias: transaction3kvvK3OPPhMeta?.alias || [],
    redirect: transaction3kvvK3OPPhMeta?.redirect,
    component: () => import("/app/pages/program/[clubId]/transaction.vue").then(m => m.default || m)
  },
  {
    name: indexu9QcDESI3cMeta?.name ?? "program",
    path: indexu9QcDESI3cMeta?.path ?? "/program",
    meta: indexu9QcDESI3cMeta || {},
    alias: indexu9QcDESI3cMeta?.alias || [],
    redirect: indexu9QcDESI3cMeta?.redirect,
    component: () => import("/app/pages/program/index.vue").then(m => m.default || m)
  },
  {
    name: index3wV7fz535qMeta?.name ?? "programs",
    path: index3wV7fz535qMeta?.path ?? "/programs",
    meta: index3wV7fz535qMeta || {},
    alias: index3wV7fz535qMeta?.alias || [],
    redirect: index3wV7fz535qMeta?.redirect,
    component: () => import("/app/pages/programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexEAzWAcRdfRMeta?.name ?? "rsvp-id",
    path: indexEAzWAcRdfRMeta?.path ?? "/rsvp/:id()",
    meta: indexEAzWAcRdfRMeta || {},
    alias: indexEAzWAcRdfRMeta?.alias || [],
    redirect: indexEAzWAcRdfRMeta?.redirect,
    component: () => import("/app/pages/rsvp/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index3PcRRShzyvMeta?.name ?? "rsvp",
    path: index3PcRRShzyvMeta?.path ?? "/rsvp",
    meta: index3PcRRShzyvMeta || {},
    alias: index3PcRRShzyvMeta?.alias || [],
    redirect: index3PcRRShzyvMeta?.redirect,
    component: () => import("/app/pages/rsvp/index.vue").then(m => m.default || m)
  },
  {
    name: settingsLwEYOlkQxrMeta?.name ?? "settings",
    path: settingsLwEYOlkQxrMeta?.path ?? "/settings",
    meta: settingsLwEYOlkQxrMeta || {},
    alias: settingsLwEYOlkQxrMeta?.alias || [],
    redirect: settingsLwEYOlkQxrMeta?.redirect,
    component: () => import("/app/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: indexyNQEpNx7yEMeta?.name ?? "students",
    path: indexyNQEpNx7yEMeta?.path ?? "/students",
    meta: indexyNQEpNx7yEMeta || {},
    alias: indexyNQEpNx7yEMeta?.alias || [],
    redirect: indexyNQEpNx7yEMeta?.redirect,
    component: () => import("/app/pages/students/index.vue").then(m => m.default || m)
  },
  {
    name: payment_45successrUBkSkXmASMeta?.name ?? "text-messaging-payment-success",
    path: payment_45successrUBkSkXmASMeta?.path ?? "/text-messaging/payment-success",
    meta: payment_45successrUBkSkXmASMeta || {},
    alias: payment_45successrUBkSkXmASMeta?.alias || [],
    redirect: payment_45successrUBkSkXmASMeta?.redirect,
    component: () => import("/app/pages/text-messaging/payment-success.vue").then(m => m.default || m)
  },
  {
    name: plansWfo8Jh1TCwMeta?.name ?? "text-messaging-plans",
    path: plansWfo8Jh1TCwMeta?.path ?? "/text-messaging/plans",
    meta: plansWfo8Jh1TCwMeta || {},
    alias: plansWfo8Jh1TCwMeta?.alias || [],
    redirect: plansWfo8Jh1TCwMeta?.redirect,
    component: () => import("/app/pages/text-messaging/plans.vue").then(m => m.default || m)
  },
  {
    name: usage_45historyxqHZ1EZbn3Meta?.name ?? "text-messaging-usage-history",
    path: usage_45historyxqHZ1EZbn3Meta?.path ?? "/text-messaging/usage-history",
    meta: usage_45historyxqHZ1EZbn3Meta || {},
    alias: usage_45historyxqHZ1EZbn3Meta?.alias || [],
    redirect: usage_45historyxqHZ1EZbn3Meta?.redirect,
    component: () => import("/app/pages/text-messaging/usage-history.vue").then(m => m.default || m)
  },
  {
    name: indexL9SrZLs43zMeta?.name ?? "transaction",
    path: indexL9SrZLs43zMeta?.path ?? "/transaction",
    meta: indexL9SrZLs43zMeta || {},
    alias: indexL9SrZLs43zMeta?.alias || [],
    redirect: indexL9SrZLs43zMeta?.redirect,
    component: () => import("/app/pages/transaction/index.vue").then(m => m.default || m)
  },
  {
    name: unauthorizedUnTSWnoGh4Meta?.name ?? "unauthorized",
    path: unauthorizedUnTSWnoGh4Meta?.path ?? "/unauthorized",
    meta: unauthorizedUnTSWnoGh4Meta || {},
    alias: unauthorizedUnTSWnoGh4Meta?.alias || [],
    redirect: unauthorizedUnTSWnoGh4Meta?.redirect,
    component: () => import("/app/pages/unauthorized.vue").then(m => m.default || m)
  },
  {
    name: indexnq54o81oozMeta?.name ?? "users",
    path: indexnq54o81oozMeta?.path ?? "/users",
    meta: indexnq54o81oozMeta || {},
    alias: indexnq54o81oozMeta?.alias || [],
    redirect: indexnq54o81oozMeta?.redirect,
    component: () => import("/app/pages/users/index.vue").then(m => m.default || m)
  }
]