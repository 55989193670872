import VueAppleLogin from 'vue-apple-login';
import moment from "moment";

// redirectURI: "https://aphelia.io/WebSignUpPage",
// redirectURI: "https://dev.aphelia.io/WebSignUpPage",
// redirectURI: "https://test.aphelia.io/WebSignUpPage",


export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(VueAppleLogin, {
        clientId: "com.technuf.apheliaevent",
        scope: "name email",
        redirectURI: "https://aphelia.io/WebSignUpPage",
        state: moment().valueOf().toString(),
        usePopup: true,
        response_type: "code",
        response_mode: "form_post",
        nonce: "ApheliaEvent"
    });
})