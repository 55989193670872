import {BrowserCacheLocation, EventType, PublicClientApplication} from "@azure/msal-browser";

let tokenExpirationTimer: any;

export const useMSAuth = () => {

    const config = useRuntimeConfig();

    const msalConfig = {
        auth: {
            clientId: config.public.clientId,
            authority: config.public.authority,
            redirectUri: config.public.redirectUri,
            // redirectUri: 'http://localhost:3000',
            postLogoutRedirectUri: config.public.postLogoutRedirectUri,
            navigateToLoginRequestUrl: true,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: true,
        },
        system: {
            tokenRenewalOffsetSeconds: 300,
        },
    };

    let msalInstance = new PublicClientApplication(msalConfig);

    async function initialize() {
        await msalInstance.initialize();

        // Handle redirect promise after login or redirect
        await msalInstance
            .handleRedirectPromise() // Handles the redirect promise and obtains the response
            .then(handleResponse)
            .catch((err) => {
                console.log(err)
                throw new Error(err);
            });

        // Add event callback for login success
        msalInstance.addEventCallback((event) => {
            if (event.eventType === EventType.LOGIN_SUCCESS) {
                setupTokenExpirationTimer();
            }
        });

    }

    // Handle the response after login or redirect
    function handleResponse(resp: any) {
        if (resp?.account) {
            setupTokenExpirationTimer();
        } else {
            console.log("LOGIN");
        }
    }

    // Set up timer for refreshing access token upon expiration
    function setupTokenExpirationTimer() {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            const account = accounts[0];
            if (account.idTokenClaims && account.idTokenClaims.exp) {
                const tokenExpirationTime = account.idTokenClaims.exp * 1000;
                const currentTime = Date.now();
                const timeUntilExpiration = tokenExpirationTime - currentTime;

                clearTimeout(tokenExpirationTimer);

                tokenExpirationTimer = setTimeout(() => {
                    refreshAccessToken(account);
                }, timeUntilExpiration);
            }
        }
    }

    // Refresh access token
    async function refreshAccessToken(account: any) {
        try {
            const response = await msalInstance.acquireTokenSilent({
                account,
                scopes: ["User.Read"],
            });
            console.log("Refreshed Access Token:", response.accessToken);
            setupTokenExpirationTimer();
        } catch (err) {
            console.error("Token refresh error:", err);
            //signOut(account.homeAccountId);
        }
    }

    const loginRequest = {
        scopes: ["User.Read"],

    };

    // Sign in with redirect
    async function signIn() {
        try {
            await msalInstance.loginPopup(loginRequest);
        } catch (err) {
            console.log("Login error:", err);
        }
    }

    // Acquire access token silently
    async function acquireAllTokenAndInfoSilent() {
        const accounts = msalInstance.getAllAccounts();

        let userInfoList = []
        if (accounts.length > 0) {

            for (let account of accounts) {
                msalInstance.setActiveAccount(account);
                try {

                    let data = msalInstance.getAccount(account);
                    const response = await msalInstance.acquireTokenSilent({
                        account,
                        scopes: ["User.Read"],
                    });

                    if(data){
                        let profileImage = await getProfileImage(response.accessToken);
                        let userInfo = {
                            email: data['username']??'',
                            name:data['name']??'',
                            profilePicture: profileImage??'',
                            accessToken: response.accessToken
                        };
                        userInfoList.push(userInfo);
                    }

                } catch (err) {
                    return userInfoList;
                }

                return userInfoList;
            }
        } else {
            console.error("No accounts found");
            return [];
        }
    }


    // Get all MSAL accounts
    function getAccounts() {
        return msalInstance.getAllAccounts();
    }

    // Check if user is authenticated
    function isAuthenticated() {
        return getAccounts().length > 0;
    }

    // Sign out user
    function signOut(accountId: string) {
        const account = accountId
            ? msalInstance.getAccountByHomeId(accountId)
            : null;
        if (account) {
            msalInstance.logoutRedirect({
                account,
            });
            localStorage.clear();
        } else {
            console.error("Account not found");
        }
    }
    const getProfileImage = async (authToken:string) => {

        if(authToken){
            const response = await fetch(
                "https://graph.microsoft.com/v1.0/me/photo/$value",
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status != 404) {
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                return url;
            }
        }

    };

    return {
        initialize,
        msalInstance,
        getProfileImage,
        signIn,
        getAccounts,
        acquireAllTokenAndInfoSilent,
        isAuthenticated,
        signOut,
    }

}
